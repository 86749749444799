import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as API from "../../api";
import { setToLocalStorage } from "../../utils/localStorage";

export const sign_in = createAsyncThunk(
  "auth/sign_in",
  async (props, { rejectWithValue }) => {
    try {
      const { data, status } = await API.loginUser(props);
      // const newToken = await API.generateNewToken({
      //   user_id: data.data.user_id,
      // });

      // console.log(data);

      // const modifiedMenu = data.data.menus.map((main) => {
      //   if (!Array.isArray(main.menu)) {
      //     return { ...main, menu: [main.menu] };
      //   }

      //   if (main.menu[0][0] !== undefined) {
      //     const menu = [];

      //     main.menu.forEach((item) => {
      //       item.forEach((item2) => {
      //         menu.push(item2);
      //       });
      //     });
      //     return { ...main, menu: menu };
      //   }

      //   return main;
      // });

      const actualData = {
        ...data.data,
        // menus: modifiedMenu,
        // email: newToken.data.data?.email,
        // location: newToken.data.data?.location,
      };

      // setToLocalStorage("st-admin-credentials", actualData);
      // setToLocalStorage("st-admin-other-token", newToken.data.data.token);

      return { data: actualData, message: data?.message, status };
    } catch (err) {
      console.log(err);
      const { status } = err.response;
      const { data, message } = err.response.data;
      return rejectWithValue({ data, message, status });
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    status: "idle",
    error: null,
    user: null,
  },
  extraReducers: {
    [sign_in.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
      state.error = null;
    },
    [sign_in.fulfilled]: (state, action) => {
      state.user = { ...action.payload.data };
      state.status = "idle";
      state.loading = false;
    },
    [sign_in.rejected]: (state, action) => {
      state.error = { ...action.payload };
      state.status = "failed";
      state.loading = false;
    },
  },
});

export default authSlice.reducer;
