import { getUser } from "./localStorage";

const offboarding = getUser()?.for_offboarding === 1;

export const menus = !offboarding ? [
  {
    "id": 1,
    "label": "Main Menu",
    "code_snippet": "Main Menu",
    "menu": [
      {
        "id": 1,
        "label": "Dashboard",
        "code_snippet": "Dashboard",
        "permission": [
          "Read",
          "List",
          "Generate"
        ],
        "sub_menu": []
      }
    ]
  },
  {
    "id": 2,
    "label": "Account",
    "code_snippet": "Account",
    "menu": [
      {
        "id": 2,
        "label": "Profile",
        "code_snippet": "Profile",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      {
        "id": 3,
        "label": "My Documents",
        "code_snippet": "My Documents",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      // {
      //   "id": 4,
      //   "label": "My Schedule",
      //   "code_snippet": "My Schedule",
      //   "permission": [
      //     "Create",
      //     "Read",
      //     "Update",
      //     "Delete",
      //     "List",
      //     "Generate"
      //   ],
      //   "sub_menu": []
      // },
      {
        "id": 5,
        "label": "Attendance Summary",
        "code_snippet": "Attendance Summary",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      {
        "id": 6,
        "label": "File A Request",
        "code_snippet": "File A Request",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 1,
            "label": "Leave",
            "code_snippet": "Leave",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 2,
            "label": "Certificate of Attendance",
            "code_snippet": "Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 3,
            "label": "Schedule Adjustment",
            "code_snippet": "Schedule Adjustment",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 4,
            "label": "Overtime",
            "code_snippet": "Overtime",
            "permission": [],
            "sub_level": [],
          },
          // {
          //   "id": 5,
          //   "label": "Undertime",
          //   "code_snippet": "Undertime",
          //   "permission": [],
          //   "sub_level": [],
          // },
          {
            "id": 6,
            "label": "Official Business",
            "code_snippet": "Official Business",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 7,
            "label": "Asset",
            "code_snippet": "Asset",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 8,
            "label": "Reimbursement",
            "code_snippet": "Reimbursement",
            "permission": [],
            "sub_level": [],
          }
        ],
      },
      {
        "id": 7,
        "label": "My Requests",
        "code_snippet": "My Requests",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 8,
            "label": "My Leaves",
            "code_snippet": "My Leaves",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 9,
            "label": "My Certificate of Attendance",
            "code_snippet": "My Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 10,
            "label": "My Schedule Adjustments",
            "code_snippet": "My Schedule Adjustments",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 11,
            "label": "My Overtimes",
            "code_snippet": "My Overtimes",
            "permission": [],
            "sub_level": [],
          },
          // {
          //   "id": 12,
          //   "label": "My Undertimes",
          //   "code_snippet": "My Undertimes",
          //   "permission": [],
          //   "sub_level": [],
          // },
          {
            "id": 13,
            "label": "My Official Businesses",
            "code_snippet": "My Official Businesses",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 14,
            "label": "My Assets",
            "code_snippet": "My Assets",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 15,
            "label": "My Reimbursements",
            "code_snippet": "My Reimbursements",
            "permission": [],
            "sub_level": [],
          }
        ],
      },
    ]
  },
  // {
  //   "id": 3,
  //   "label": "LMS",
  //   "code_snippet": "LMS",
  //   "menu": [
  //     {
  //       "id": 8,
  //       "label": "LMS",
  //       "code_snippet": "LMS",
  //       "permission": [
  //         "Create",
  //         "Read",
  //         "Update",
  //         "Delete",
  //         "List",
  //         "Generate"
  //       ],
  //       "sub_menu": []
  //     },
  //   ],
  // },
  {
    "id": 4,
    "label": "Referrals",
    "code_snippet": "Referrals",
    "menu": [
      {
        "id": 9,
        "label": "Referrals",
        "code_snippet": "Referrals",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
    ],
  },
  // {
  //   "id": 5,
  //   "label": "Payroll",
  //   "code_snippet": "Payroll",
  //   "menu": [
  //     {
  //       "id": 10,
  //       "label": "My Payroll Summary",
  //       "code_snippet": "My Payroll Summary",
  //       "permission": [
  //         "Create",
  //         "Read",
  //         "Update",
  //         "Delete",
  //         "List",
  //         "Generate"
  //       ],
  //       "sub_menu": []
  //     },
  //   ],
  // },
] : [
  {
    "id": 1,
    "label": "Main Menu",
    "code_snippet": "Main Menu",
    "menu": [
      {
        "id": 1,
        "label": "Dashboard",
        "code_snippet": "Dashboard",
        "permission": [
          "Read",
          "List",
          "Generate"
        ],
        "sub_menu": []
      }
    ]
  },
  {
    "id": 2,
    "label": "Account",
    "code_snippet": "Account",
    "menu": [
      {
        "id": 5,
        "label": "Attendance Summary",
        "code_snippet": "Attendance Summary",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      {
        "id": 6,
        "label": "File A Request",
        "code_snippet": "File A Request",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 1,
            "label": "Leave",
            "code_snippet": "Leave",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 2,
            "label": "Certificate of Attendance",
            "code_snippet": "Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
        ],
      },
      {
        "id": 7,
        "label": "My Requests",
        "code_snippet": "My Requests",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 8,
            "label": "My Leaves",
            "code_snippet": "My Leaves",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 9,
            "label": "My Certificate of Attendance",
            "code_snippet": "My Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
        ],
      },
    ]
  },
  {
    "id": 4,
    "label": "Referrals",
    "code_snippet": "Referrals",
    "menu": [
      {
        "id": 9,
        "label": "Referrals",
        "code_snippet": "Referrals",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
    ],
  },
  {
    "id": 5,
    "label": "Offboarding",
    "code_snippet": "Referrals",
    "menu": [
      {
        "id": 10,
        "label": "Offboarding Clearance",
        "code_snippet": "Offboarding Clearance",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
    ],
  },
  // {
  //   "id": 5,
  //   "label": "Payroll",
  //   "code_snippet": "Payroll",
  //   "menu": [
  //     {
  //       "id": 10,
  //       "label": "My Payroll Summary",
  //       "code_snippet": "My Payroll Summary",
  //       "permission": [
  //         "Create",
  //         "Read",
  //         "Update",
  //         "Delete",
  //         "List",
  //         "Generate"
  //       ],
  //       "sub_menu": []
  //     },
  //   ],
  // },
];

console.log(menus);
